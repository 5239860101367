/*!
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       
                                                                                                                                                                                                       

*/
/* Kendo skin */
.k-theme-test-class,
.ktb-theme-id-office365 {
  opacity: 0;
}
.ktb-var-accent {
  color: #cde6f7;
}
.ktb-var-base {
  color: #0072c6;
}
.ktb-var-background {
  color: #fff;
}
.ktb-var-border-radius {
  border-radius: 0;
}
.ktb-var-normal-background {
  color: #fff;
}
.ktb-var-normal-gradient {
  background-image: none;
}
.ktb-var-normal-text-color {
  color: #333333;
}
.ktb-var-hover-background {
  color: #f4f4f4;
}
.ktb-var-hover-gradient {
  background-image: none;
}
.ktb-var-hover-text-color {
  color: #000;
}
.ktb-var-selected-background {
  color: #cde6f7;
}
.ktb-var-selected-gradient {
  background-image: none;
}
.ktb-var-selected-text-color {
  color: #333333;
}
.ktb-var-error {
  color: #ffcdd2;
}
.ktb-var-warning {
  color: #fff19d;
}
.ktb-var-success {
  color: #c8e6c9;
}
.ktb-var-info {
  color: #bbdefb;
}
.ktb-var-series-a {
  color: #0072c6;
}
.ktb-var-series-b {
  color: #5db2ff;
}
.ktb-var-series-c {
  color: #008a17;
}
.ktb-var-series-d {
  color: #82ba00;
}
.ktb-var-series-e {
  color: #ff8f32;
}
.ktb-var-series-f {
  color: #ac193d;
}
.k-grid-norecords-template {
  background-color: #fff;
  border: 1px solid #c9c9c9;
}
.k-in,
.k-item,
.k-window-action {
  border-color: transparent;
}
/* main colors */
.k-block,
.k-widget {
  background-color: #fff;
}
.k-block,
.k-widget,
.k-input,
.k-textbox,
.k-group,
.k-content,
.k-header,
.k-filter-row > th,
.k-editable-area,
.k-separator,
.k-colorpicker .k-i-arrow-s,
.k-textbox > input,
.k-autocomplete,
.k-dropdown-wrap,
.k-toolbar,
.k-group-footer td,
.k-grid-footer,
.k-footer-template td,
.k-state-default,
.k-state-default .k-select,
.k-state-disabled,
.k-grid-header,
.k-grid-header-wrap,
.k-grid-header-locked,
.k-grid-footer-locked,
.k-grid-content-locked,
.k-grid td,
.k-grid td.k-state-selected,
.k-grid-footer-wrap,
.k-pager-wrap,
.k-pager-wrap .k-link,
.k-pager-refresh,
.k-grouping-header,
.k-grouping-header .k-group-indicator,
.k-panelbar > .k-item > .k-link,
.k-panel > .k-item > .k-link,
.k-panelbar .k-panel,
.k-panelbar .k-content,
.k-treemap-tile,
.k-calendar th,
.k-slider-track,
.k-splitbar,
.k-dropzone-active,
.k-tiles,
.k-toolbar,
.k-tooltip,
.k-button-group .k-tool,
.k-upload-files {
  border-color: #c9c9c9;
}
.k-group,
.k-toolbar,
.k-grouping-header,
.k-pager-wrap,
.k-group-footer td,
.k-grid-footer,
.k-footer-template td,
.k-widget .k-status,
.k-calendar th,
.k-dropzone-hovered,
.k-widget.k-popup {
  background-color: #fff;
}
.k-grouping-row td,
td.k-group-cell,
.k-resize-handle-inner {
  background-color: #fff;
}
.k-list-container {
  border-color: #c9c9c9;
  background-color: #fff;
}
.k-content,
.k-editable-area,
.k-panelbar > li.k-item,
.k-panel > li.k-item,
.k-tiles {
  background-color: #fff;
}
.k-alt,
.k-separator,
.k-resource.k-alt,
.k-pivot-layout > tbody > tr:first-child > td:first-child {
  background-color: #fff;
}
.k-pivot-rowheaders .k-alt .k-alt,
.k-header.k-alt {
  background-color: #ebebeb;
}
.k-textbox,
.k-autocomplete.k-header,
.k-dropdown-wrap.k-state-active,
.k-picker-wrap.k-state-active,
.k-numeric-wrap.k-state-active {
  border-color: #c9c9c9;
  background-color: #0072c6;
}
.k-textbox > input,
.k-autocomplete .k-input,
.k-dropdown-wrap .k-input,
.k-autocomplete.k-state-focused .k-input,
.k-dropdown-wrap.k-state-focused .k-input,
.k-picker-wrap.k-state-focused .k-input,
.k-numeric-wrap.k-state-focused .k-input {
  border-color: #c9c9c9;
}
input.k-textbox,
textarea.k-textbox,
input.k-textbox:hover,
textarea.k-textbox:hover,
.k-textbox > input {
  background: none;
}
.k-input,
input.k-textbox,
textarea.k-textbox,
input.k-textbox:hover,
textarea.k-textbox:hover,
.k-textbox > input,
.k-multiselect-wrap {
  background-color: #fff;
  color: #333333;
}
.k-input[readonly] {
  background-color: #fff;
  color: #333333;
}
.k-block,
.k-widget,
.k-popup,
.k-content,
.k-toolbar,
.k-dropdown .k-input {
  color: #333333;
}
.k-inverse {
  color: #fff;
}
.k-block {
  color: #333333;
}
.k-link:link,
.k-link:visited,
.k-nav-current.k-state-hover .k-link {
  color: #0072c6;
}
.k-tabstrip-items .k-link,
.k-panelbar > li > .k-link {
  color: #333333;
}
.k-header,
.k-treemap-title,
.k-grid-header .k-header > .k-link {
  color: #333333;
}
.k-header,
.k-grid-header,
.k-toolbar,
.k-dropdown-wrap,
.k-picker-wrap,
.k-numeric-wrap,
.k-grouping-header,
.k-pager-wrap,
.k-textbox,
.k-button,
.k-progressbar,
.k-draghandle,
.k-autocomplete,
.k-state-highlight,
.k-tabstrip-items .k-item,
.k-panelbar .k-tabstrip-items .k-item,
.km-pane-wrapper > .km-pane > .km-view > .km-content {
  background-image: none;
  background-position: 50% 50%;
  background-color: #fff;
}
.k-widget.k-tooltip {
  background-image: none;
}
.k-block,
.k-header,
.k-grid-header,
.k-toolbar,
.k-grouping-header,
.k-pager-wrap,
.k-button,
.k-draghandle,
.k-treemap-tile,
html .km-pane-wrapper .k-header {
  background-color: #fff;
}
/* icons */
.k-icon:hover,
.k-state-hover .k-icon,
.k-state-selected .k-icon,
.k-state-focused .k-icon,
.k-column-menu .k-state-hover .k-sprite,
.k-column-menu .k-state-active .k-sprite,
.k-pager-numbers .k-current-page .k-link:hover:after,
.k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view.k-state-hover > .k-link:after {
  opacity: 1;
}
.k-icon,
.k-state-disabled .k-icon,
.k-column-menu .k-sprite,
.k-pager-numbers .k-current-page .k-link:after,
.k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view > .k-link:after {
  opacity: 0.6;
}
.k-mobile-list .k-check:checked,
.k-mobile-list .k-edit-field [type=checkbox]:checked,
.k-mobile-list .k-edit-field [type=radio]:checked {
  opacity: 0.6;
}
.k-tool {
  border-color: transparent;
}
.k-icon,
.k-tool-icon,
.k-grouping-dropclue,
.k-drop-hint,
.k-column-menu .k-sprite,
.k-grid-mobile .k-resize-handle-inner:before,
.k-grid-mobile .k-resize-handle-inner:after,
.k-pager-numbers .k-current-page .k-link:after,
.k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view > .k-link:after,
.k-gantt-views > .k-current-view > .k-link:after {
  background-image: url('Office365/sprite.png');
  border-color: transparent;
}
/* IE will ignore the above selectors if these are added too */
.k-mobile-list .k-check:checked,
.k-mobile-list .k-edit-field [type=checkbox]:checked,
.k-mobile-list .k-edit-field [type=radio]:checked {
  background-image: url('Office365/sprite.png');
  border-color: transparent;
}
.k-loading,
.k-state-hover .k-loading {
  background-image: url('Office365/loading.gif');
  background-position: 50% 50%;
}
.k-loading-image {
  background-image: url('Office365/loading-image.gif');
}
.k-loading-color {
  background-color: #fff;
}
.k-button {
  color: #333333;
  border-color: #f4f4f4;
  background-color: #f4f4f4;
}
.k-draghandle {
  border-color: #0072c6;
  background-color: #0072c6;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.k-draghandle:hover {
  border-color: #0072c6;
  background-color: #0072c6;
  -webkit-box-shadow: none;
          box-shadow: none;
}
/* Scheduler */
.k-scheduler {
  color: #333333;
  background-color: #fff;
}
.k-scheduler-layout {
  color: #333333;
}
.k-scheduler-datecolumn,
.k-scheduler-groupcolumn {
  background-color: #fff;
  color: #333333;
}
.k-scheduler-times tr,
.k-scheduler-times th,
.k-scheduler-table td,
.k-scheduler-header th,
.k-scheduler-header-wrap,
.k-scheduler-times {
  border-color: #c9c9c9;
}
.k-nonwork-hour {
  background-color: #fff;
}
.k-gantt .k-nonwork-hour {
  background-color: rgba(0, 0, 0, 0.02);
}
.k-gantt .k-header.k-nonwork-hour {
  background-color: rgba(0, 0, 0, 0.2);
}
.k-scheduler-table .k-today,
.k-today > .k-scheduler-datecolumn,
.k-today > .k-scheduler-groupcolumn {
  background-color: #fff;
}
.k-scheduler-now-arrow {
  border-left-color: #dbdbdb;
}
.k-scheduler-now-line {
  background-color: #dbdbdb;
}
.k-event,
.k-task-complete {
  border-color: #5fb2f6;
  background: #5fb2f6 0 -257px none repeat-x;
  color: #333333;
}
.k-event-inverse {
  color: #333333;
}
.k-scheduler-content .k-event.k-state-selected {
  background-position: 0 0;
  -webkit-box-shadow: 0 0 0 2px #333333, inset 4px 0 0 0 rgba(255, 255, 255, 0.5);
          box-shadow: 0 0 0 2px #333333, inset 4px 0 0 0 rgba(255, 255, 255, 0.5);
}
.k-event .k-resize-handle:after,
.k-task-single .k-resize-handle:after {
  background-color: #fff;
}
.k-scheduler-marquee:before,
.k-scheduler-marquee:after {
  border-color: #cde6f7;
}
.k-panelbar .k-content,
.k-panelbar .k-panel,
.k-panelbar .k-item {
  background-color: #fff;
  color: #333333;
  border-color: #c9c9c9;
}
.k-panelbar > li > .k-link {
  color: #333333;
}
.k-panelbar > .k-item > .k-link {
  border-color: #c9c9c9;
}
.k-panel > li.k-item {
  background-color: #fff;
}
/* states */
.k-state-active,
.k-state-active:hover,
.k-active-filter,
.k-tabstrip .k-state-active {
  background-color: #fff;
  border-color: #0072c6;
  color: #333333;
}
.k-fieldselector .k-list-container {
  background-color: #fff;
}
.k-button:focus,
.k-button.k-state-focused {
  border-color: #0072c6;
}
.k-button:hover,
.k-button.k-state-hover {
  color: #333333;
  border-color: #eaeaea;
  background-color: #eaeaea;
}
.k-button:active,
.k-button.k-state-active {
  color: #333333;
  background-color: #0072c6;
  border-color: #0072c6;
}
.k-button:active:hover,
.k-button.k-state-active:hover {
  color: #fff;
  border-color: #005b9d;
  background-color: #005b9d;
}
.k-button:focus:not(.k-state-disabled):not([disabled]) {
  -webkit-box-shadow: none;
          box-shadow: none;
}
.k-button:focus:active:not(.k-state-disabled):not([disabled]) {
  -webkit-box-shadow: none;
          box-shadow: none;
}
.k-menu .k-state-hover > .k-state-active {
  background-color: transparent;
}
.k-state-highlight {
  background: #fff;
  color: #333333;
}
.k-state-focused,
.k-grouping-row .k-state-focused {
  border-color: #0072c6;
}
.k-calendar .k-link {
  color: #333333;
}
.k-calendar .k-footer {
  padding: 0;
}
.k-calendar .k-footer .k-nav-today {
  color: #333333;
  text-decoration: none;
  background-color: #fff;
}
.k-calendar .k-footer .k-nav-today:hover,
.k-calendar .k-footer .k-nav-today.k-state-hover {
  background-color: #fff;
  text-decoration: underline;
}
.k-calendar .k-footer .k-nav-today:active {
  background-color: #fff;
}
.k-calendar .k-link.k-nav-fast {
  color: #333333;
}
.k-calendar .k-nav-fast.k-state-hover {
  text-decoration: none;
  background-color: #f4f4f4;
  color: #000;
}
.k-calendar .k-link.k-state-hover,
.k-window-titlebar .k-link {
  border-radius: 0;
}
.k-calendar .k-footer .k-link {
  border-radius: 0;
}
.k-calendar th {
  background-color: #fff;
}
.k-calendar-container.k-group {
  border-color: #c9c9c9;
}
.k-state-selected,
.k-state-selected:link,
.k-state-selected:visited,
.k-list > .k-state-selected,
.k-list > .k-state-highlight,
.k-panel > .k-state-selected,
.k-ghost-splitbar-vertical,
.k-ghost-splitbar-horizontal,
.k-draghandle.k-state-selected:hover,
.k-scheduler .k-scheduler-toolbar .k-state-selected,
.k-scheduler .k-today.k-state-selected,
.k-marquee-color {
  color: #333333;
  background-color: #cde6f7;
  border-color: #cde6f7;
}
.k-virtual-item.k-first,
.k-group-header + .k-list > .k-item.k-first,
.k-static-header + .k-list > .k-item.k-first {
  border-top-color: #8f8f8f;
}
.k-group-header + div > .k-list > .k-item.k-first {
  -webkit-box-shadow: 0 -1px 0px 0px #8f8f8f;
          box-shadow: 0 -1px 0px 0px #8f8f8f;
}
.k-popup > .k-group-header,
.k-popup > .k-virtual-wrap > .k-group-header {
  background: #8f8f8f;
  color: #333333;
}
.k-popup .k-list .k-item > .k-group {
  background: #8f8f8f;
  color: #fff;
  border-bottom-left-radius: 0;
}
.k-marquee-text {
  color: #333333;
}
.k-state-focused,
.k-list > .k-state-focused,
td.k-state-focused,
.k-button.k-state-focused {
  -webkit-box-shadow: none;
          box-shadow: none;
}
.k-listview > .k-state-focused,
.k-listview > .k-state-focused.k-state-selected {
  -webkit-box-shadow: inset 0 0 0 1px #0072c6;
          box-shadow: inset 0 0 0 1px #0072c6;
}
.k-state-focused.k-state-selected,
.k-list > .k-state-focused.k-state-selected,
td.k-state-focused.k-state-selected {
  -webkit-box-shadow: none;
          box-shadow: none;
}
.k-ie8 .k-panelbar span.k-state-focused,
.k-ie8 .k-menu li.k-state-focused,
.k-ie8 .k-listview > .k-state-focused,
.k-ie8 .k-grid-header th.k-state-focused,
.k-ie8 td.k-state-focused,
.k-ie8 .k-tool.k-state-hover,
.k-ie8 .k-button:focus,
.k-ie8 .k-button.k-state-focused,
.k-list > .k-state-selected.k-state-focused,
.k-list-optionlabel.k-state-selected.k-state-focused {
  -webkit-box-shadow: none;
          box-shadow: none;
}
.k-state-selected > .k-link,
.k-panelbar > li > .k-state-selected,
.k-panelbar > li.k-state-default > .k-link.k-state-selected {
  color: #333333;
}
.k-state-hover,
.k-state-hover:hover,
.k-splitbar-horizontal-hover:hover,
.k-splitbar-vertical-hover:hover,
.k-list > .k-state-hover,
.k-scheduler .k-scheduler-toolbar ul li.k-state-hover,
.k-pager-wrap .k-link:hover,
.k-dropdown .k-state-focused,
.k-filebrowser-dropzone,
.k-mobile-list .k-item > .k-link:active,
.k-mobile-list .k-item > .k-label:active,
.k-mobile-list .k-edit-label.k-check:active,
.k-mobile-list .k-recur-view .k-check:active {
  color: #000;
  background-color: #f4f4f4;
  border-color: #8f8f8f;
}
/* this selector should be used separately, otherwise old IEs ignore the whole rule */
.k-mobile-list .k-scheduler-timezones .k-edit-field:nth-child(2):active {
  color: #000;
  background-color: #f4f4f4;
  border-color: #8f8f8f;
}
.k-ie8 .k-window-titlebar .k-state-hover {
  border-color: #8f8f8f;
}
.k-state-hover > .k-select,
.k-state-focused > .k-select {
  border-color: #8f8f8f;
}
.k-button:hover,
.k-button.k-state-hover,
.k-button:focus,
.k-button.k-state-focused,
.k-textbox:hover,
.k-state-hover,
.k-state-hover:hover,
.k-pager-wrap .k-link:hover,
.k-other-month.k-state-hover .k-link,
div.k-filebrowser-dropzone em,
.k-draghandle:hover {
  background-image: none;
}
.k-pager-wrap {
  background-color: #fff;
  color: #333333;
}
.k-autocomplete.k-state-active,
.k-picker-wrap.k-state-active,
.k-numeric-wrap.k-state-active,
.k-dropdown-wrap.k-state-active,
.k-state-active,
.k-state-active:hover,
.k-state-active > .k-link,
.k-button:active,
.k-panelbar > .k-item > .k-state-focused {
  background-image: none;
}
.k-state-selected,
.k-button:active,
.k-button.k-state-active,
.k-draghandle.k-state-selected:hover {
  background-image: none;
}
.k-button:active,
.k-button.k-state-active,
.k-draghandle.k-state-selected:hover {
  background-position: 50% 50%;
}
.k-tool-icon {
  background-image: url('Office365/sprite.png');
}
.k-state-hover > .k-link,
.k-other-month.k-state-hover .k-link,
div.k-filebrowser-dropzone em {
  color: #000;
}
.k-autocomplete.k-state-hover,
.k-autocomplete.k-state-focused,
.k-picker-wrap.k-state-hover,
.k-picker-wrap.k-state-focused,
.k-numeric-wrap.k-state-hover,
.k-numeric-wrap.k-state-focused,
.k-dropdown-wrap.k-state-hover,
.k-dropdown-wrap.k-state-focused {
  background-color: #eaeaea;
  background-image: none;
  background-position: 50% 50%;
  border-color: #0072c6;
}
.km-pane-wrapper .k-mobile-list input:not([type="checkbox"]):not([type="radio"]),
.km-pane-wrapper .km-pane .k-mobile-list select:not([multiple]),
.km-pane-wrapper .k-mobile-list textarea,
.k-dropdown .k-state-focused .k-input {
  color: #333333;
}
.k-dropdown .k-state-hover .k-input {
  color: #000;
}
.k-state-error {
  border-color: #dbdbdb;
  background-color: #ffd7db;
  color: #6b6b6b;
}
.k-state-disabled {
  opacity: .7;
}
.k-ie8 .k-state-disabled {
  filter: alpha(opacity=70);
}
.k-tile-empty.k-state-selected,
.k-loading-mask.k-state-selected {
  border-width: 0;
  background-image: none;
  background-color: transparent;
}
.k-state-disabled,
.k-state-disabled .k-link,
.k-state-disabled .k-button,
.k-other-month,
.k-other-month .k-link,
.k-dropzone em,
.k-dropzone .k-upload-status,
.k-tile-empty strong,
.k-slider .k-draghandle {
  color: #aaaaaa;
}
/* Progressbar */
.k-progressbar-indeterminate {
  background: url('Office365/indeterminate.gif');
}
.k-progressbar-indeterminate .k-progress-status-wrap,
.k-progressbar-indeterminate .k-state-selected {
  display: none;
}
/* Slider */
.k-slider-track {
  background-color: #c9c9c9;
}
.k-slider-selection {
  background-color: #cde6f7;
}
.k-slider-horizontal .k-tick {
  background-image: url('Office365/slider-h.gif');
}
.k-slider-vertical .k-tick {
  background-image: url('Office365/slider-v.gif');
}
/* Tooltip */
.k-widget.k-tooltip {
  border-color: #c9c9c9;
  background-color: #fff;
  color: #333333;
}
.k-widget.k-tooltip-validation {
  border-color: #fff19d;
  background-color: #fff19d;
  color: #6a5b00;
}
/* Bootstrap theme fix */
.input-prepend .k-tooltip-validation,
.input-append .k-tooltip-validation {
  font-size: 12px;
  position: relative;
  top: 3px;
}
.k-callout-n {
  border-bottom-color: #c9c9c9;
}
.k-callout-w {
  border-right-color: #c9c9c9;
}
.k-callout-s {
  border-top-color: #c9c9c9;
}
.k-callout-e {
  border-left-color: #c9c9c9;
}
.k-tooltip-validation .k-callout-n {
  border-bottom-color: #fff19d;
}
.k-tooltip-validation .k-callout-w {
  border-right-color: #fff19d;
}
.k-tooltip-validation .k-callout-s {
  border-top-color: #fff19d;
}
.k-tooltip-validation .k-callout-e {
  border-left-color: #fff19d;
}
/* Splitter */
.k-splitbar {
  background-color: #f4f4f4;
}
.k-restricted-size-vertical,
.k-restricted-size-horizontal {
  background-color: #6b6b6b;
}
/* Upload */
.k-file {
  background-color: #fff;
  border-color: #c9c9c9;
}
.k-file-progress {
  color: #333333;
}
.k-file-progress .k-progress {
  background-color: #bbdefb;
}
.k-file-success {
  color: #333333;
}
.k-file-success .k-progress {
  background-color: #c8e6c9;
}
.k-file-error {
  color: #333333;
}
.k-file-error .k-progress {
  background-color: #ffcdd2;
}
/* ImageBrowser */
.k-tile {
  border-color: #fff;
}
.k-textbox:hover,
.k-tiles li.k-state-hover {
  border-color: #8f8f8f;
}
.k-tiles li.k-state-selected {
  border-color: #cde6f7;
}
.k-filebrowser .k-tile .k-folder,
.k-filebrowser .k-tile .k-file {
  background-image: url('Office365/imagebrowser.png');
  -webkit-background-size: auto auto;
          background-size: auto auto;
}
/* TreeMap */
.k-leaf,
.k-leaf.k-state-hover:hover {
  color: #fff;
}
.k-leaf.k-inverse,
.k-leaf.k-inverse.k-state-hover:hover {
  color: #000;
}
/* Shadows */
.k-widget,
.k-button {
  -webkit-box-shadow: none;
          box-shadow: none;
}
.k-slider,
.k-treeview,
.k-upload {
  -webkit-box-shadow: none;
          box-shadow: none;
}
.k-state-hover {
  -webkit-box-shadow: none;
          box-shadow: none;
}
.k-textbox:focus,
.k-autocomplete.k-state-focused,
.k-dropdown-wrap.k-state-focused,
.k-picker-wrap.k-state-focused,
.k-numeric-wrap.k-state-focused {
  -webkit-box-shadow: none;
          box-shadow: none;
}
.k-state-selected {
  -webkit-box-shadow: none;
          box-shadow: none;
}
.k-state-active {
  -webkit-box-shadow: none;
          box-shadow: none;
}
.k-grid td.k-state-selected.k-state-focused {
  background-color: #daedf9;
}
.k-popup,
.k-menu .k-menu-group,
.k-grid .k-filter-options,
.k-time-popup,
.k-datepicker-calendar,
.k-autocomplete.k-state-border-down,
.k-autocomplete.k-state-border-up,
.k-dropdown-wrap.k-state-active,
.k-picker-wrap.k-state-active,
.k-multiselect.k-state-focused,
.k-filebrowser .k-image,
.k-tooltip {
  -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.18);
          box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.18);
}
.k-treemap-tile.k-state-hover {
  -webkit-box-shadow: inset 0 0 0 3px #c9c9c9;
          box-shadow: inset 0 0 0 3px #c9c9c9;
}
/* Window */
.k-window {
  border-color: rgba(0, 0, 0, 0.18);
  -webkit-box-shadow: 1px 1px 7px 1px rgba(128, 128, 128, 0.18);
          box-shadow: 1px 1px 7px 1px rgba(128, 128, 128, 0.18);
  background-color: #fff;
}
.k-window.k-state-focused {
  border-color: rgba(0, 0, 0, 0.18);
  -webkit-box-shadow: 1px 1px 7px 1px rgba(0, 0, 0, 0.18);
          box-shadow: 1px 1px 7px 1px rgba(0, 0, 0, 0.18);
}
.k-window.k-window-maximized,
.k-window-maximized .k-window-titlebar,
.k-window-maximized .k-window-content {
  border-radius: 0;
}
.k-shadow {
  -webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.18);
          box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.18);
}
.k-inset {
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.18);
          box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.18);
}
/* Selection */
.k-editor-inline ::-moz-selection {
  background-color: #cde6f7;
  text-shadow: none;
  color: #fff;
}
.k-editor-inline ::selection {
  background-color: #cde6f7;
  text-shadow: none;
  color: #fff;
}
.k-editor-inline ::-moz-selection {
  background-color: #cde6f7;
  text-shadow: none;
  color: #fff;
}
/* Notification */
.k-widget.k-notification.k-notification-info {
  background-color: #bbdefb;
  color: #4d4d4d;
  border-color: #bbdefb;
}
.k-widget.k-notification.k-notification-success {
  background-color: #c8e6c9;
  color: #4d4d4d;
  border-color: #c8e6c9;
}
.k-widget.k-notification.k-notification-warning {
  background-color: #fff19d;
  color: #4d4d4d;
  border-color: #fff19d;
}
.k-widget.k-notification.k-notification-error {
  background-color: #ffcdd2;
  color: #4d4d4d;
  border-color: #ffcdd2;
}
/* Gantt */
.k-gantt .k-treelist {
  background: #fff;
}
.k-gantt .k-treelist .k-alt {
  background-color: #e6e6e6;
}
.k-gantt .k-treelist .k-state-selected,
.k-gantt .k-treelist .k-state-selected td,
.k-gantt .k-treelist .k-alt.k-state-selected,
.k-gantt .k-treelist .k-alt.k-state-selected > td {
  background-color: #cde6f7;
}
.k-gantt .k-treelist .k-state-selected:hover,
.k-gantt .k-treelist .k-state-selected:hover td {
  background-color: #d7e4ed;
}
.k-task-dot:after {
  background-color: #333333;
  border-color: #333333;
}
.k-task-dot:hover:after {
  background-color: #fff;
}
.k-task-summary {
  border-color: #d1d1d1;
  background: #d1d1d1;
}
.k-task-milestone,
.k-task-summary-complete {
  border-color: #333333;
  background: #333333;
}
.k-state-selected.k-task-summary {
  border-color: #000;
  background: #000;
}
.k-state-selected.k-task-milestone,
.k-state-selected .k-task-summary-complete {
  border-color: #cde6f7;
  background: #cde6f7;
}
.k-task-single {
  background-color: #81c2f8;
  border-color: #5fb2f6;
  color: #333333;
}
.k-state-selected.k-task-single {
  border-color: #cde6f7;
}
.k-line {
  background-color: #333333;
  color: #333333;
}
.k-state-selected.k-line {
  background-color: #cde6f7;
  color: #cde6f7;
}
.k-resource {
  background-color: #fff;
}
/* PivotGrid */
.k-i-kpi-decrease,
.k-i-kpi-denied,
.k-i-kpi-equal,
.k-i-kpi-hold,
.k-i-kpi-increase,
.k-i-kpi-open {
  background-image: url('Office365/sprite_kpi.png');
}
/* Border radius */
.k-block,
.k-button,
.k-textbox,
.k-drag-clue,
.k-touch-scrollbar,
.k-window,
.k-window-titleless .k-window-content,
.k-window-action,
.k-inline-block,
.k-grid .k-filter-options,
.k-grouping-header .k-group-indicator,
.k-autocomplete,
.k-multiselect,
.k-combobox,
.k-dropdown,
.k-dropdown-wrap,
.k-datepicker,
.k-timepicker,
.k-colorpicker,
.k-datetimepicker,
.k-notification,
.k-numerictextbox,
.k-picker-wrap,
.k-numeric-wrap,
.k-colorpicker,
.k-list-container,
.k-calendar-container,
.k-calendar td,
.k-calendar .k-link,
.k-treeview .k-in,
.k-editor-inline,
.k-tooltip,
.k-tile,
.k-slider-track,
.k-slider-selection,
.k-upload {
  border-radius: 0;
}
.k-tool {
  text-align: center;
  vertical-align: middle;
}
.k-tool.k-group-start,
.k-toolbar .k-split-button .k-button,
.k-toolbar .k-button-group .k-group-start {
  border-radius: 0 0 0 0;
}
.k-rtl .k-tool.k-group-start,
.k-rtl .k-toolbar .k-split-button .k-button,
.k-rtl .k-toolbar .k-button-group .k-group-start {
  border-radius: 0 0 0 0;
}
.k-tool.k-group-end,
.k-toolbar .k-button-group .k-group-end,
.k-toolbar .k-split-button .k-split-button-arrow {
  border-radius: 0 0 0 0;
}
.k-rtl .k-tool.k-group-end,
.k-rtl .k-toolbar .k-button-group .k-group-end,
.k-rtl .k-toolbar .k-split-button .k-split-button-arrow {
  border-radius: 0 0 0 0;
}
.k-group-start.k-group-end.k-tool {
  border-radius: 0;
}
.k-calendar-container.k-state-border-up,
.k-list-container.k-state-border-up,
.k-autocomplete.k-state-border-up,
.k-multiselect.k-state-border-up,
.k-dropdown-wrap.k-state-border-up,
.k-picker-wrap.k-state-border-up,
.k-numeric-wrap.k-state-border-up,
.k-window-content,
.k-filter-menu {
  border-radius: 0 0 0 0;
}
.k-autocomplete.k-state-border-up .k-input,
.k-dropdown-wrap.k-state-border-up .k-input,
.k-picker-wrap.k-state-border-up .k-input,
.k-picker-wrap.k-state-border-up .k-selected-color,
.k-numeric-wrap.k-state-border-up .k-input {
  border-radius: 0 0 0 0;
}
.k-multiselect.k-state-border-up .k-multiselect-wrap {
  border-radius: 0 0 0 0;
}
.k-window-titlebar,
.k-block > .k-header,
.k-tabstrip-items .k-item,
.k-panelbar .k-tabstrip-items .k-item,
.k-tabstrip-items .k-link,
.k-calendar-container.k-state-border-down,
.k-list-container.k-state-border-down,
.k-autocomplete.k-state-border-down,
.k-multiselect.k-state-border-down,
.k-dropdown-wrap.k-state-border-down,
.k-picker-wrap.k-state-border-down,
.k-numeric-wrap.k-state-border-down {
  border-radius: 0 0 0 0;
}
.k-split-button.k-state-border-down > .k-button {
  border-radius: 0 0 0 0;
}
.k-split-button.k-state-border-up > .k-button {
  border-radius: 0 0 0 0;
}
.k-split-button.k-state-border-down > .k-split-button-arrow {
  border-radius: 0 0 0 0;
}
.k-split-button.k-state-border-up > .k-split-button-arrow {
  border-radius: 0 0 0 0;
}
.k-dropdown-wrap .k-input,
.k-picker-wrap .k-input,
.k-numeric-wrap .k-input {
  border-radius: 0 0 0 0;
}
.k-rtl .k-dropdown-wrap .k-input,
.k-rtl .k-picker-wrap .k-input,
.k-rtl .k-numeric-wrap .k-input {
  border-radius: 0 0 0 0;
}
.k-numeric-wrap .k-link {
  border-radius: 0 0 0 0;
}
.k-numeric-wrap .k-link + .k-link {
  border-radius: 0 0 0 0;
}
.k-colorpicker .k-selected-color {
  border-radius: 0 0 0 0;
}
.k-rtl .k-colorpicker .k-selected-color {
  border-radius: 0 0 0 0;
}
.k-autocomplete.k-state-border-down .k-input {
  border-radius: 0 0 0 0;
}
.k-dropdown-wrap.k-state-border-down .k-input,
.k-picker-wrap.k-state-border-down .k-input,
.k-picker-wrap.k-state-border-down .k-selected-color,
.k-numeric-wrap.k-state-border-down .k-input {
  border-radius: 0 0 0 0;
}
.k-numeric-wrap .k-link.k-state-selected {
  background-color: #0072c6;
}
.k-multiselect.k-state-border-down .k-multiselect-wrap {
  border-radius: 0 0 0 0;
}
.k-dropdown-wrap .k-select,
.k-picker-wrap .k-select,
.k-numeric-wrap .k-select,
.k-datetimepicker .k-select + .k-select,
.k-list-container.k-state-border-right {
  border-radius: 0 0 0 0;
}
.k-rtl .k-dropdown-wrap .k-select,
.k-rtl .k-picker-wrap .k-select,
.k-rtl .k-numeric-wrap .k-select,
.k-rtl .k-datetimepicker .k-select + .k-select,
.k-rtl .k-list-container.k-state-border-right {
  border-radius: 0 0 0 0;
}
.k-numeric-wrap.k-expand-padding .k-input {
  border-radius: 0;
}
.k-textbox > input,
.k-autocomplete .k-input,
.k-multiselect-wrap {
  border-radius: 0;
}
.k-list .k-state-hover,
.k-list .k-state-focused,
.k-list .k-state-highlight,
.k-list .k-state-selected,
.k-fieldselector .k-list .k-item,
.k-list-optionlabel,
.k-dropzone {
  border-radius: 0;
}
.k-slider .k-button,
.k-grid .k-slider .k-button {
  border-radius: 0;
}
.k-draghandle {
  border-radius: 0;
}
.k-scheduler-toolbar > ul li:first-child,
.k-scheduler-toolbar > ul li:first-child .k-link,
.k-scheduler-toolbar > ul.k-scheduler-views li:first-child + li,
.k-scheduler-toolbar > ul.k-scheduler-views li:first-child + li .k-link {
  border-radius: 0 0 0 0;
}
.k-rtl .k-scheduler-toolbar > ul li:first-child,
.k-rtl .k-scheduler-toolbar > ul li:first-child .k-link,
.k-rtl .k-scheduler-toolbar > ul.k-scheduler-views li:first-child + li,
.k-rtl .k-scheduler-toolbar > ul.k-scheduler-views li:first-child + li .k-link,
.km-view.k-popup-edit-form .k-scheduler-toolbar > ul li:last-child,
.km-view.k-popup-edit-form .k-scheduler-toolbar > ul li:last-child .k-link {
  border-radius: 0 0 0 0;
}
.k-scheduler-phone .k-scheduler-toolbar > ul li.k-nav-today,
.k-scheduler-phone .k-scheduler-toolbar > ul li.k-nav-today .k-link,
.k-edit-field > .k-scheduler-navigation {
  border-radius: 0;
}
.k-scheduler-toolbar .k-nav-next,
.k-scheduler-toolbar ul + ul li:last-child,
.k-scheduler-toolbar .k-nav-next .k-link,
.k-scheduler-toolbar ul + ul li:last-child .k-link {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.k-rtl .k-scheduler-toolbar .k-nav-next,
.k-rtl .k-scheduler-toolbar ul + ul li:last-child,
.k-rtl .k-scheduler-toolbar .k-nav-next .k-link,
.k-rtl .k-scheduler-toolbar ul + ul li:last-child .k-link {
  border-radius: 0 0 0 0;
}
.k-scheduler div.k-scheduler-footer ul li,
.k-scheduler div.k-scheduler-footer .k-link {
  border-radius: 0;
}
.k-more-events,
.k-event,
.k-task-single,
.k-task-complete,
.k-event .k-link {
  border-radius: 0;
}
.k-scheduler-mobile .k-event {
  border-radius: -1;
}
/* Adaptive Grid */
.k-grid-mobile .k-column-active + th.k-header {
  border-left-color: #333333;
}
html .km-pane-wrapper .km-widget,
.k-ie .km-pane-wrapper .k-widget,
.k-ie .km-pane-wrapper .k-group,
.k-ie .km-pane-wrapper .k-content,
.k-ie .km-pane-wrapper .k-header,
.k-ie .km-pane-wrapper .k-popup-edit-form .k-edit-field .k-button,
.km-pane-wrapper .k-mobile-list .k-item,
.km-pane-wrapper .k-mobile-list .k-edit-label,
.km-pane-wrapper .k-mobile-list .k-edit-field {
  color: #333333;
}
@media screen and (-ms-high-contrast: active) and (-ms-high-contrast: none) {
  div.km-pane-wrapper a {
    color: #333333;
  }
}
.km-pane-wrapper .k-mobile-list .k-item,
.km-pane-wrapper .k-mobile-list .k-edit-field,
.km-pane-wrapper .k-mobile-list .k-recur-view > .k-edit-field .k-check {
  background-color: #fff;
  border-top: 1px solid #c9c9c9;
}
.km-pane-wrapper .k-mobile-list .k-edit-field textarea {
  outline-width: 0;
}
.km-pane-wrapper .k-mobile-list .k-item.k-state-selected {
  background-color: #cde6f7;
  border-top-color: #cde6f7;
}
.km-pane-wrapper .k-mobile-list .k-recur-view > .k-edit-field .k-check:first-child {
  border-top-color: transparent;
}
.km-pane-wrapper .k-mobile-list .k-item:last-child {
  -webkit-box-shadow: inset 0 -1px 0 #c9c9c9;
          box-shadow: inset 0 -1px 0 #c9c9c9;
}
.km-pane-wrapper .k-mobile-list > ul > li > .k-link,
.km-pane-wrapper .k-mobile-list .k-recur-view > .k-edit-label:nth-child(3),
.km-pane-wrapper #recurrence .km-scroll-container > .k-edit-label:first-child {
  color: #7d7d7d;
}
.km-pane-wrapper .k-mobile-list > ul > li > .k-link {
  border-bottom: 1px solid #c9c9c9;
}
.km-pane-wrapper .k-mobile-list .k-edit-field {
  -webkit-box-shadow: 0 1px 1px #c9c9c9;
          box-shadow: 0 1px 1px #c9c9c9;
}
.km-actionsheet .k-grid-delete,
.km-actionsheet .k-scheduler-delete,
.km-pane-wrapper .k-scheduler-delete,
.km-pane-wrapper .k-filter-menu .k-button[type=reset] {
  color: #fff;
  border-color: #dbdbdb;
  background-color: red;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0.3)), to(rgba(255, 255, 255, 0.15)));
  background-image: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.15));
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.15));
}
.km-actionsheet .k-grid-delete:active,
.km-actionsheet .k-scheduler-delete:active,
.km-pane-wrapper .k-scheduler-delete:active,
.km-pane-wrapper .k-filter-menu .k-button[type=reset]:active {
  background-color: #990000;
}
/* /Column Menu */
.k-autocomplete.k-state-default,
.k-picker-wrap.k-state-default,
.k-numeric-wrap.k-state-default,
.k-dropdown-wrap.k-state-default {
  background-image: none;
  background-position: 50% 50%;
  background-color: #fff;
  border-color: #bababa;
}
.k-autocomplete.k-state-hover,
.k-picker-wrap.k-state-hover,
.k-numeric-wrap.k-state-hover,
.k-dropdown-wrap.k-state-hover {
  background-color: #eaeaea;
  background-image: none;
  background-position: 50% 50%;
  border-color: #8b8b8b;
}
.k-multiselect.k-header {
  border-color: #bababa;
}
.k-multiselect.k-header.k-state-hover {
  border-color: #8b8b8b;
}
.k-autocomplete.k-state-focused,
.k-picker-wrap.k-state-focused,
.k-numeric-wrap.k-state-focused,
.k-dropdown-wrap.k-state-focused,
.k-multiselect.k-header.k-state-focused {
  background-color: #eaeaea;
  background-image: none;
  background-position: 50% 50%;
  border-color: #0072c6;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.k-list-container {
  color: #333333;
}
.k-dropdown .k-input,
.k-dropdown .k-state-focused .k-input,
.k-menu .k-popup {
  color: #333333;
}
.k-state-default > .k-select {
  border-color: #bababa;
}
.k-state-hover > .k-select {
  border-color: #8b8b8b;
}
.k-state-focused > .k-select {
  border-color: #0072c6;
}
.k-tabstrip:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
}
.k-tabstrip-items .k-state-default .k-link,
.k-panelbar > li.k-state-default > .k-link {
  color: #333333;
}
.k-tabstrip-items .k-state-hover .k-link,
.k-tabstrip-items .k-state-active .k-link {
  color: #0072c6;
}
.k-panelbar > li.k-state-hover > .k-link,
.k-panelbar > li.k-state-default > .k-link.k-state-hover {
  color: #000;
}
.k-panelbar > li > .k-state-focused.k-state-hover {
  background: #f4f4f4;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.k-tabstrip-items .k-state-default {
  border-color: transparent;
}
.k-tabstrip-items .k-state-hover {
  border-color: #8f8f8f;
}
.k-tabstrip-items .k-state-active,
.k-panelbar .k-tabstrip-items .k-state-active {
  background-color: #fff;
  background-image: none;
  border-color: #0072c6;
}
.k-tabstrip .k-content.k-state-active {
  background-color: #fff;
  color: #333333;
}
.k-menu.k-header,
.k-menu .k-item {
  border-color: #fff;
}
.k-column-menu,
.k-column-menu .k-item,
.k-overflow-container .k-overflow-group {
  border-color: #c9c9c9;
}
.k-overflow-container .k-overflow-group {
  -webkit-box-shadow: inset 0 1px 0 #ffffff, 0 1px 0 #ffffff;
          box-shadow: inset 0 1px 0 #ffffff, 0 1px 0 #ffffff;
}
.k-toolbar-first-visible.k-overflow-group,
.k-overflow-container .k-overflow-group + .k-overflow-group {
  -webkit-box-shadow: 0 1px 0 #ffffff;
          box-shadow: 0 1px 0 #ffffff;
}
.k-toolbar-last-visible.k-overflow-group {
  -webkit-box-shadow: inset 0 1px 0 #ffffff;
          box-shadow: inset 0 1px 0 #ffffff;
}
.k-column-menu .k-separator {
  border-color: #c9c9c9;
  background-color: transparent;
}
.k-menu .k-group {
  border-color: #c9c9c9;
}
.k-grid-filter.k-state-active {
  background-color: #fff;
}
.k-grid-filter.k-state-active .k-icon {
  opacity: 1;
}
.k-grouping-row td,
.k-group-footer td,
.k-grid-footer td {
  color: #333333;
  border-color: #c9c9c9;
  font-weight: bold;
}
.k-grouping-header {
  color: #333333;
}
.k-header,
.k-grid-header-wrap,
.k-grid .k-grouping-header,
.k-grid-header,
.k-pager-wrap,
.k-pager-wrap .k-textbox,
.k-pager-wrap .k-link,
.k-grouping-header .k-group-indicator,
.k-gantt-toolbar .k-state-default {
  border-color: #c9c9c9;
}
.k-primary,
.k-overflow-container .k-primary {
  color: #fff;
  border-color: #0072c6;
  background-image: none;
  background-position: 50% 50%;
  background-color: #0072c6;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.k-primary:focus,
.k-primary.k-state-focused {
  color: #fff;
  border-color: #00467a;
  background-image: none;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.k-primary:hover {
  color: #fff;
  border-color: #005b9d;
  background-image: none;
  background-color: #005b9d;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.k-primary:focus:active:not(.k-state-disabled):not([disabled]),
.k-primary:focus:not(.k-state-disabled):not([disabled]) {
  -webkit-box-shadow: none;
          box-shadow: none;
}
.k-primary:active {
  color: #fff;
  border-color: #004c84;
  background-image: none;
  background-color: #004c84;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.k-primary.k-state-disabled,
.k-state-disabled .k-primary,
.k-primary.k-state-disabled:hover,
.k-state-disabled .k-primary:hover,
.k-primary.k-state-disabled:hover,
.k-state-disabled .k-primary:active,
.k-primary.k-state-disabled:active {
  color: #7d7d7d;
  border-color: #e5e5e5;
  background-color: #e5e5e5;
  background-image: none;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.k-pager-numbers .k-link,
.k-treeview .k-in {
  border-color: transparent;
}
.k-treeview .k-icon,
.k-scheduler-table .k-icon,
.k-grid .k-hierarchy-cell .k-icon {
  background-color: transparent;
  border-radius: 0;
}
.k-scheduler-table .k-state-hover .k-icon {
  background-color: transparent;
}
.k-button:focus,
.k-split-button:focus {
  outline: none;
}
.k-split-button:focus {
  -webkit-box-shadow: 0 0 0px 1px #0072c6;
          box-shadow: 0 0 0px 1px #0072c6;
}
.k-split-button:focus > .k-button {
  background: transparent;
  border-color: transparent;
}
.k-split-button:focus > .k-button.k-split-button-arrow {
  border-left-color: #0072c6;
}
.k-editor .k-tool:focus {
  outline: 0;
  border-color: #0072c6;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.k-checkbox-label:before {
  border-color: #666666;
  background: #fff;
  border-radius: 1px;
}
.k-checkbox-label:hover:before,
.k-checkbox:checked + .k-checkbox-label:hover:before {
  border-color: #212121;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.k-checkbox:checked + .k-checkbox-label:before {
  background-color: #fff;
  border-color: #666666;
  color: #333333;
}
.k-checkbox-label:active:before {
  -webkit-box-shadow: none;
          box-shadow: none;
  border-color: #0072c6;
}
.k-checkbox:checked + .k-checkbox-label:active:before {
  -webkit-box-shadow: none;
          box-shadow: none;
  border-color: #0072c6;
}
.k-checkbox:disabled + .k-checkbox-label {
  color: #aaaaaa;
}
.k-checkbox:disabled + .k-checkbox-label:hover:before {
  -webkit-box-shadow: none;
          box-shadow: none;
}
.k-checkbox:disabled + .k-checkbox-label:before,
.k-checkbox:checked:disabled + .k-checkbox-label:before,
.k-checkbox:checked:disabled + .k-checkbox-label:active:before,
.k-checkbox:checked:disabled + .k-checkbox-label:hover:before {
  color: #aaaaaa;
  background: #fff;
  border-color: #aaaaaa;
  border-radius: 1px;
}
.k-checkbox:focus + .k-checkbox-label:before {
  border-color: #0072c6;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.k-checkbox:indeterminate + .k-checkbox-label:after {
  background-color: #666666;
  background-image: none;
  border-color: #666666;
  border-radius: 0px;
}
.k-checkbox:indeterminate:hover + .k-checkbox-label:after {
  border-color: #212121;
  background-color: #212121;
}
.k-radio-label:before {
  border-color: #666666;
  border-radius: 50%;
  background-color: #fff;
  border-width: 1px;
}
.k-radio-label:hover:before,
.k-radio:checked + .k-radio-label:hover:before {
  border-color: #212121;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.k-radio:checked + .k-radio-label:after {
  background-color: #212121;
  border-radius: 50%;
}
.k-radio-label:active:before {
  border-color: #666666;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.k-radio:checked + .k-radio-label:active:before {
  -webkit-box-shadow: none;
          box-shadow: none;
  border-color: #666666;
}
.k-radio:disabled + .k-radio-label {
  color: #aaaaaa;
}
.k-radio:disabled + .k-radio-label:before,
.k-radio:disabled + .k-radio-label:active:before,
.k-radio:disabled + .k-radio-label:hover:after,
.k-radio:disabled + .k-radio-label:hover:before {
  background: #fff;
  border-color: #bfbfbf;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.k-radio:focus + .k-radio-label:before {
  border-color: #666666;
  -webkit-box-shadow: none;
          box-shadow: none;
}
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2) {
  .k-icon:not(.k-loading),
  .k-grouping-dropclue,
  .k-drop-hint,
  .k-callout,
  .k-tool-icon,
  .k-state-hover .k-tool-icon,
  .k-state-active .k-tool-icon,
  .k-state-active.k-state-hover .k-tool-icon,
  .k-state-selected .k-tool-icon,
  .k-state-selected.k-state-hover .k-tool-icon,
  .k-column-menu .k-sprite,
  .k-mobile-list .k-check:checked,
  .k-mobile-list .k-edit-field [type=checkbox]:checked,
  .k-mobile-list .k-edit-field [type=radio]:checked {
    background-image: url('Office365/sprite_2x.png');
    -webkit-background-size: 340px 336px;
            background-size: 340px 336px;
  }
  .k-dropdown-wrap .k-input,
  .k-picker-wrap .k-input,
  .k-numeric-wrap .k-input {
    border-radius: 0 0 0 0;
  }
  .k-i-kpi-decrease,
  .k-i-kpi-denied,
  .k-i-kpi-equal,
  .k-i-kpi-hold,
  .k-i-kpi-increase,
  .k-i-kpi-open {
    background-image: url('Office365/sprite_kpi_2x.png');
    -webkit-background-size: 96px 16px;
            background-size: 96px 16px;
  }
}
@media screen and (-ms-high-contrast: active) {
  .k-editor-toolbar-wrap .k-dropdown-wrap.k-state-focused,
  .k-editor-toolbar-wrap .k-button-group .k-tool:focus {
    border-color: #fff;
  }
}
/* Responsive styles */
@media only screen and (max-width: 1024px) {
  .k-webkit .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view,
  .k-ff .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view,
  .k-ie11 .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view,
  .k-safari .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view,
  .k-webkit .k-pager-numbers .k-current-page .k-link,
  .k-ff .k-pager-numbers .k-current-page .k-link,
  .k-ie11 .k-pager-numbers .k-current-page .k-link,
  .k-safari .k-pager-numbers .k-current-page .k-link {
    background-image: none;
    background-position: 50% 50%;
    background-color: #fff;
    border-color: #c9c9c9;
  }
  .k-webkit .k-pager-numbers .k-current-page .k-link,
  .k-ff .k-pager-numbers .k-current-page .k-link,
  .k-ie11 .k-pager-numbers .k-current-page .k-link,
  .k-safari .k-pager-numbers .k-current-page .k-link {
    border-color: #c9c9c9;
  }
  .k-webkit .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view,
  .k-ff .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view,
  .k-ie11 .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view,
  .k-safari .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view {
    border-radius: 0;
  }
  .k-webkit .k-scheduler-toolbar > ul.k-scheduler-views.k-state-expanded > li,
  .k-ff .k-scheduler-toolbar > ul.k-scheduler-views.k-state-expanded > li,
  .k-ie11 .k-scheduler-toolbar > ul.k-scheduler-views.k-state-expanded > li,
  .k-safari .k-scheduler-toolbar > ul.k-scheduler-views.k-state-expanded > li {
    border-radius: 0;
  }
  .k-webkit .k-scheduler-toolbar > ul.k-scheduler-views.k-state-expanded > li.k-current-view,
  .k-ff .k-scheduler-toolbar > ul.k-scheduler-views.k-state-expanded > li.k-current-view,
  .k-ie11 .k-scheduler-toolbar > ul.k-scheduler-views.k-state-expanded > li.k-current-view,
  .k-safari .k-scheduler-toolbar > ul.k-scheduler-views.k-state-expanded > li.k-current-view {
    border-radius: 0 0 0 0;
  }
  .k-webkit .k-scheduler-toolbar > ul li:first-child,
  .k-ff .k-scheduler-toolbar > ul li:first-child,
  .k-ie11 .k-scheduler-toolbar > ul li:first-child,
  .k-safari .k-scheduler-toolbar > ul li:first-child,
  .k-webkit .k-scheduler-toolbar > ul li:first-child .k-link,
  .k-ff .k-scheduler-toolbar > ul li:first-child .k-link,
  .k-ie11 .k-scheduler-toolbar > ul li:first-child .k-link,
  .k-safari .k-scheduler-toolbar > ul li:first-child .k-link,
  .k-webkit .k-scheduler-toolbar > ul.k-scheduler-views li,
  .k-ff .k-scheduler-toolbar > ul.k-scheduler-views li,
  .k-ie11 .k-scheduler-toolbar > ul.k-scheduler-views li,
  .k-safari .k-scheduler-toolbar > ul.k-scheduler-views li,
  .k-webkit .k-scheduler-toolbar > ul.k-scheduler-views li .k-link,
  .k-ff .k-scheduler-toolbar > ul.k-scheduler-views li .k-link,
  .k-ie11 .k-scheduler-toolbar > ul.k-scheduler-views li .k-link,
  .k-safari .k-scheduler-toolbar > ul.k-scheduler-views li .k-link {
    border-radius: 0;
  }
  .k-webkit .k-scheduler-toolbar > ul.k-scheduler-views li:last-child,
  .k-ff .k-scheduler-toolbar > ul.k-scheduler-views li:last-child,
  .k-ie11 .k-scheduler-toolbar > ul.k-scheduler-views li:last-child,
  .k-safari .k-scheduler-toolbar > ul.k-scheduler-views li:last-child,
  .k-webkit .k-scheduler-toolbar > ul.k-scheduler-views li:last-child .k-link,
  .k-ff .k-scheduler-toolbar > ul.k-scheduler-views li:last-child .k-link,
  .k-ie11 .k-scheduler-toolbar > ul.k-scheduler-views li:last-child .k-link,
  .k-safari .k-scheduler-toolbar > ul.k-scheduler-views li:last-child .k-link {
    border-radius: 0 0 0 0;
  }
  .k-webkit .k-pager-numbers .k-current-page .k-link:hover,
  .k-ff .k-pager-numbers .k-current-page .k-link:hover,
  .k-ie11 .k-pager-numbers .k-current-page .k-link:hover,
  .k-safari .k-pager-numbers .k-current-page .k-link:hover,
  .k-webkit .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view:hover,
  .k-ff .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view:hover,
  .k-ie11 .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view:hover,
  .k-safari .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view:hover {
    border-color: #8f8f8f;
    background-image: none;
    background-color: #f4f4f4;
  }
  .k-webkit .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view > .k-link,
  .k-ff .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view > .k-link,
  .k-ie11 .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view > .k-link,
  .k-safari .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view > .k-link {
    color: #333333;
    min-width: 75px;
  }
  .k-webkit .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view:hover > .k-link,
  .k-ff .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view:hover > .k-link,
  .k-ie11 .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view:hover > .k-link,
  .k-safari .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view:hover > .k-link {
    color: #000;
  }
  .k-webkit .k-pager-numbers .k-current-page .k-link:after,
  .k-ff .k-pager-numbers .k-current-page .k-link:after,
  .k-ie11 .k-pager-numbers .k-current-page .k-link:after,
  .k-safari .k-pager-numbers .k-current-page .k-link:after,
  .k-webkit .k-scheduler-views > li.k-state-selected > .k-link:after,
  .k-ff .k-scheduler-views > li.k-state-selected > .k-link:after,
  .k-ie11 .k-scheduler-views > li.k-state-selected > .k-link:after,
  .k-safari .k-scheduler-views > li.k-state-selected > .k-link:after {
    display: block;
    content: "";
    position: absolute;
    top: 50%;
    margin-top: -0.5em;
    right: 0.333em;
    width: 1.333em;
    height: 1.333em;
  }
  .k-webkit .k-pager-numbers.k-state-expanded,
  .k-ff .k-pager-numbers.k-state-expanded,
  .k-ie11 .k-pager-numbers.k-state-expanded,
  .k-safari .k-pager-numbers.k-state-expanded,
  .k-webkit .k-scheduler-toolbar > ul.k-scheduler-views.k-state-expanded,
  .k-ff .k-scheduler-toolbar > ul.k-scheduler-views.k-state-expanded,
  .k-ie11 .k-scheduler-toolbar > ul.k-scheduler-views.k-state-expanded,
  .k-safari .k-scheduler-toolbar > ul.k-scheduler-views.k-state-expanded {
    border-width: 1px 1px 0 1px;
    border-style: solid;
    border-color: #c9c9c9;
    background-color: #fff;
    border-radius: 0 0 0 0;
    -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.18);
            box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.18);
  }
  .k-webkit .k-scheduler-toolbar > ul.k-scheduler-views.k-state-expanded,
  .k-ff .k-scheduler-toolbar > ul.k-scheduler-views.k-state-expanded,
  .k-ie11 .k-scheduler-toolbar > ul.k-scheduler-views.k-state-expanded,
  .k-safari .k-scheduler-toolbar > ul.k-scheduler-views.k-state-expanded {
    border-width: 1px;
    background-image: none;
    border-radius: 0;
  }
  .k-webkit .k-pager-numbers .k-state-selected,
  .k-ff .k-pager-numbers .k-state-selected,
  .k-ie11 .k-pager-numbers .k-state-selected,
  .k-safari .k-pager-numbers .k-state-selected,
  .k-webkit .k-pager-numbers .k-link,
  .k-ff .k-pager-numbers .k-link,
  .k-ie11 .k-pager-numbers .k-link,
  .k-safari .k-pager-numbers .k-link {
    border-radius: 0;
  }
  .k-webkit .k-widget.k-grid .k-pager-nav + .k-pager-numbers,
  .k-ff .k-widget.k-grid .k-pager-nav + .k-pager-numbers,
  .k-ie11 .k-widget.k-grid .k-pager-nav + .k-pager-numbers,
  .k-safari .k-widget.k-grid .k-pager-nav + .k-pager-numbers {
    position: absolute;
  }
}
.k-button:hover .k-icon,
.k-tool-icon:hover,
.k-state-hover .k-tool-icon,
.k-state-selected .k-tool-icon,
.k-state-focused .k-tool-icon,
.k-button:hover .k-tool-icon,
.k-splitbar.k-splitbar-horizontal-hover .k-icon,
.k-splitbar.k-splitbar-vertical-hover .k-icon,
div.k-splitbar.k-state-focused .k-icon,
.k-textbox:hover > .k-icon,
.k-grouping-header .k-group-delete,
.k-grouping-header .k-button-icon:hover > .k-icon.k-group-delete,
.k-grouping-header .k-si-arrow-n,
.k-grouping-header .k-link:hover > .k-icon.k-si-arrow-n,
.k-grouping-header .k-si-arrow-s,
.k-grouping-header .k-link:hover > .k-icon.k-si-arrow-s,
.k-grid-toolbar .k-i-pdf,
.k-grid-toolbar .k-button:hover > .k-i-pdf,
.k-grid-toolbar .k-i-excel,
.k-grid-toolbar .k-button:hover > .k-i-excel,
.k-grid-toolbar .k-icon,
.k-scheduler-toolbar .k-icon,
.k-scheduler-footer .k-icon,
.k-scheduler-content .k-icon,
.k-gantt-toolbar .k-icon,
.k-field-actions .k-icon,
.k-notification .k-icon,
.k-pivot-configurator-settings .k-icon:hover {
  opacity: .8;
}
.k-state-disabled .k-icon,
.k-button.k-state-disabled .k-icon {
  opacity: .3;
}
.k-button:active,
.k-button.k-state-active {
  color: #fff;
}
.k-button:active:hover,
.k-button.k-state-active:hover {
  color: #fff;
  border-color: #0072c6;
  background-color: #0072c6;
}
.k-button:focus,
.k-button.k-state-focused {
  background-color: #eaeaea;
}
.k-button:focus:hover,
.k-button.k-state-focused:hover {
  border-color: #0072c6;
}
.k-primary.k-grid-update {
  color: #fff;
}
.k-primary:active,
.k-primary:focus,
.k-primary.k-state-focused,
.k-primary:active:hover,
.k-primary.k-state-active:hover,
.k-primary:focus:hover,
.k-primary.k-state-focused:hover {
  background-color: #005b9d;
  border-color: #00467a;
}
.k-button[disabled],
.k-button.k-state-disabled,
.k-state-disabled .k-button,
.k-state-disabled .k-button:hover,
.k-button.k-state-disabled:hover,
.k-state-disabled .k-button:active,
.k-button.k-state-disabled:active {
  color: #aaaaaa;
  border-color: #f4f4f4;
  background-color: #f4f4f4;
  background-image: none;
}
.k-primary.k-state-disabled,
.k-state-disabled .k-primary,
.k-primary.k-state-disabled:hover,
.k-state-disabled .k-primary:hover,
.k-primary.k-state-disabled:hover,
.k-state-disabled .k-primary:active,
.k-primary.k-state-disabled:active {
  color: #7d7d7d;
  background-color: #e5e5e5;
}
.k-numerictextbox .k-select,
.k-combobox .k-select,
.k-picker-wrap .k-select {
  border-color: #fff;
}
.k-autocomplete.k-state-focused,
.k-picker-wrap.k-state-focused,
.k-numeric-wrap.k-state-focused,
.k-dropdown-wrap.k-state-focused,
.k-multiselect.k-header.k-state-focused {
  background-color: #fff;
}
.k-textbox.k-state-disabled,
.k-state-disabled .k-picker-wrap,
.k-autocomplete.k-state-disabled,
.k-dropdown-wrap.k-state-disabled,
.k-picker-wrap.k-state-disabled,
.k-multiselect.k-state-disabled,
.k-numeric-wrap.k-state-disabled {
  border-color: #e5e5e5;
}
.k-autocomplete.k-state-border-down {
  border-bottom-width: 1px;
  border-bottom-style: solid;
  padding-bottom: 0;
}
input.k-textbox:focus {
  border-color: #0072c6;
}
.k-multiselect.k-header.k-state-hover .k-button,
.k-multiselect.k-header.k-state-focused .k-button {
  background-color: #eaeaea;
}
.k-multiselect .k-button:hover,
.k-multiselect .k-button.k-state-hover {
  color: #333333;
  border-color: #eaeaea;
  background-color: #eaeaea;
}
.k-multiselect.k-header.k-state-focused .k-button {
  border-color: #0072c6;
}
.k-multiselect.k-state-focused.k-state-border-up .k-button,
.k-multiselect.k-state-focused.k-state-border-down .k-button {
  color: #fff;
  background-color: #0072c6;
  border-color: #0072c6;
}
.k-dropdown-wrap.k-state-default.k-state-active,
.k-picker-wrap.k-state-default.k-state-active {
  background-color: #0072c6;
  border-color: #0072c6;
}
.k-picker-wrap.k-state-default.k-state-active .k-select,
.k-dropdown-wrap.k-state-default.k-state-active .k-select {
  border-color: #0072c6;
}
.k-dropdown .k-state-default.k-state-active .k-input,
.k-state-selected .k-progress-status {
  color: #fff;
}
.k-list > .k-state-hover {
  border-color: #f4f4f4;
}
.k-list > .k-state-selected {
  background-color: #cde6f7;
  border-color: #cde6f7;
}
.k-popup {
  -webkit-box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.18);
          box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.18);
}
.k-calendar .k-state-focused,
.k-calendar .k-state-selected.k-state-focused {
  -webkit-box-shadow: inset 0 0 0px 1px #0072c6;
          box-shadow: inset 0 0 0px 1px #0072c6;
}
.k-calendar .k-state-selected {
  background-color: #cde6f7;
}
.k-calendar td:active,
.k-calendar .k-state-selected.k-state-hover {
  background-color: #c2dbec;
}
.k-calendar .k-nav-fast.k-state-hover,
.k-calendar .k-nav-prev.k-state-hover,
.k-calendar .k-nav-next.k-state-hover {
  background-color: #fff;
}
.k-calendar .k-today {
  background-color: #0072c6;
}
.k-calendar .k-today .k-link {
  color: #fff;
}
.k-calendar .k-today.k-state-hover {
  background-color: #005b9d;
}
.k-calendar .k-today.k-state-focused,
.k-calendar .k-today:active,
.k-calendar .k-today.k-state-selected:active {
  -webkit-box-shadow: inset 0 0 0px 1px #00467a;
          box-shadow: inset 0 0 0px 1px #00467a;
}
.k-calendar .k-footer .k-nav-today {
  color: #0072c6;
}
.k-calendar .k-footer {
  border-color: #c9c9c9;
}
.k-menu > .k-item > .k-state-active,
.k-popup.k-context-menu.k-group .k-state-hover {
  color: #0072c6;
  background-color: #fff;
}
.k-menu .k-state-active {
  background-color: #cde6f7;
}
.k-splitbar {
  border-color: #f4f4f4;
}
.k-splitbar-horizontal-hover:hover,
.k-splitbar-vertical-hover:hover {
  background-color: #eaeaea;
  border-color: #eaeaea;
}
.k-splitbar.k-state-focused {
  background-color: #0072c6;
  border-color: #0072c6;
}
.k-marquee-color {
  background-color: #0072c6;
}
.k-grid .k-state-selected,
.k-grid td.k-state-selected.k-state-focused {
  background-color: #cde6f7;
}
.k-grid tr:hover,
.k-gantt .k-treelist tr:hover {
  background-color: #f4f4f4;
}
.k-pivot-rowheaders .k-grid tr:hover {
  background: none;
}
.k-grid tr.k-state-selected:hover,
.k-grid td.k-state-selected:hover {
  background-color: #d7e4ed;
}
.k-gantt .k-treelist .k-alt,
.k-gantt .k-header.k-nonwork-hour {
  background-color: #fff;
}
.k-grid-header th.k-state-focused,
.k-grid td.k-state-focused {
  -webkit-box-shadow: inset 0 0 0 1px #0072c6;
          box-shadow: inset 0 0 0 1px #0072c6;
}
.k-grid td,
.k-grid .k-grouping-header,
.k-header.k-grid-toolbar,
.k-grid-header-wrap {
  border-color: transparent;
}
.k-grid tr th {
  border-left-color: transparent;
}
.k-pivot .k-grid-header th.k-header,
.k-pivot-layout > tbody > tr:first-child > td:first-child {
  border-color: #c9c9c9;
}
.k-group-footer td {
  border-top-color: #c9c9c9;
}
.k-grouping-header,
.k-pager-info {
  color: #aaaaaa;
}
.k-grouping-header .k-group-indicator {
  background-color: #f4f4f4;
  border-color: #f4f4f4;
}
.k-grouping-header .k-group-indicator .k-link {
  color: #333333;
}
.k-pager-wrap > .k-link,
.k-pager-numbers .k-link,
.k-pager-numbers .k-link:hover,
.k-pager-numbers .k-state-selected {
  background: none;
  border-radius: 0;
}
.k-pager-wrap > .k-link,
.k-pager-wrap > .k-link.k-state-disabled:hover,
.k-pager-wrap > .k-link:hover,
.k-pager-numbers .k-link:hover {
  border-radius: 0;
  border-color: transparent;
  background: none;
}
.k-pager-numbers .k-link {
  border-color: transparent;
  color: #333333;
}
.k-pager-numbers .k-state-selected {
  color: #0072c6;
  border-color: #0072c6;
}
.k-progressbar {
  background-color: #e5e5e5;
  border-color: #e5e5e5;
}
.k-progressbar .k-state-default {
  border-color: #e5e5e5 #fff #e5e5e5;
}
.k-progressbar .k-state-selected {
  background-color: #0072c6;
  border-color: #0072c6;
}
.k-progressbar.k-progressbar-vertical .k-state-selected {
  border-color: #0072c6;
}
.k-toolbar .k-toggle-button {
  background-color: #fff;
  border-color: #fff;
}
.k-toolbar .k-toggle-button:hover {
  background-color: #f4f4f4;
  border-color: #f4f4f4;
}
.k-toolbar .k-toggle-button.k-state-active,
.k-toolbar .k-toggle-button:active,
.k-toolbar .k-toggle-button:active:hover {
  background-color: #cde6f7;
  border-color: #cde6f7;
}
.k-toolbar .k-toggle-button:active,
.k-toolbar .k-toggle-button.k-state-active,
.k-toolbar .k-toggle-button:active:hover,
.k-toolbar .k-toggle-button.k-state-active:hover {
  color: #333333;
}
.k-toolbar .k-toggle-button.k-state-disabled,
.k-toolbar .k-toggle-button.k-state-disabled:active,
.k-toolbar .k-toggle-button.k-state-disabled:active:hover {
  color: #aaaaaa;
  border-color: #f4f4f4;
  background-color: #f4f4f4;
  background-image: none;
}
.k-toolbar .k-overflow-anchor {
  background-color: #fff;
  border-color: #c9c9c9;
}
.k-toolbar .k-overflow-anchor:hover {
  background-color: #eaeaea;
  border-color: #eaeaea;
}
.k-toolbar .k-overflow-anchor.k-state-active {
  border-color: #eaeaea;
}
.k-toolbar .k-overflow-anchor.k-state-border-down,
.k-toolbar .k-overflow-anchor.k-state-border-up {
  background-color: #0072c6;
  border-color: #0072c6;
}
.k-tabstrip.k-header {
  background-color: #f4f4f4;
}
.k-tabstrip .k-item {
  background-color: #f4f4f4;
  border-color: #f4f4f4;
}
.k-tabstrip .k-item.k-state-active {
  background-color: #fff;
  border-color: #fff;
}
.k-tabstrip .k-content {
  border-color: transparent;
}
.k-slider-track {
  background-color: #bababa;
}
.k-slider-selection {
  background-color: #0072c6;
}
.k-draghandle {
  background-color: #0072c6;
  border-color: #0072c6;
}
.k-draghandle:hover,
.k-draghandle.k-state-selected,
.k-draghandle.k-state-selected:hover {
  background-color: #005b9d;
  border-color: #005b9d;
}
.k-flatcolorpicker .k-selected-color input.k-color-value,
.k-flatcolorpicker .k-hsv-rectangle {
  border-color: #c9c9c9;
}
.k-flatcolorpicker .k-hue-slider .k-draghandle,
.k-flatcolorpicker .k-transparency-slider .k-draghandle,
.k-flatcolorpicker .k-hue-slider .k-draghandle:hover,
.k-flatcolorpicker .k-transparency-slider .k-draghandle:hover,
.k-flatcolorpicker .k-hue-slider .k-draghandle:focus,
.k-flatcolorpicker .k-transparency-slider .k-draghandle:focus {
  background-color: #666666;
  border-color: #666666;
}
.k-flatcolorpicker .k-slider-horizontal .k-slider-track {
  -webkit-box-shadow: none;
          box-shadow: none;
}
.k-treeview .k-state-selected {
  color: #0072c6;
  background-color: #fff;
}
.k-scheduler-toolbar .k-link,
.k-gantt-toolbar .k-link {
  color: #333333;
}
.k-scheduler-toolbar .k-state-selected .k-link,
.k-gantt-toolbar .k-state-selected .k-link {
  color: #0072c6;
}
.k-scheduler-toolbar .k-nav-current .k-link,
.k-gantt-toolbar .k-nav-current .k-link {
  color: #0072c6;
}
.k-scheduler .k-scheduler-toolbar ul li,
.k-gantt .k-gantt-toolbar ul li,
.k-scheduler .k-scheduler-toolbar ul li.k-state-hover,
.k-gantt .k-gantt-toolbar ul li.k-state-hover,
.k-scheduler .k-scheduler-toolbar ul li.k-state-selected,
.k-gantt .k-gantt-toolbar ul li.k-state-selected {
  background-color: #fff;
  border-color: transparent;
}
.k-fieldselector .k-item.k-header {
  border-color: #f4f4f4;
  background-color: #f4f4f4;
}
.k-fieldselector .k-item.k-header.k-state-hover {
  border-color: #eaeaea;
  background-color: #eaeaea;
}
.k-scheduler-footer ul li {
  border-color: transparent;
}
.k-scheduler .k-event-template,
.k-scheduler .k-event-inverse {
  color: #fff;
}
.k-scheduler .k-nonwork-hour {
  background-color: #e3f1fb;
}
.k-scheduler-content .k-event {
  -webkit-box-shadow: inset 4px 0 0 0 rgba(255, 255, 255, 0.5);
          box-shadow: inset 4px 0 0 0 rgba(255, 255, 255, 0.5);
}
.k-scheduler-times tr,
.k-scheduler-times th,
.k-scheduler-table td,
.k-scheduler-header th,
.k-scheduler-header-wrap,
.k-scheduler-times {
  border-left-color: transparent;
  border-right-color: transparent;
}
.k-gantt .k-gantt-timeline tr:first-child th {
  border-color: #c9c9c9;
}
.k-gantt .k-treelist .k-alt {
  background-color: #fff;
}
.k-panelbar .k-state-selected.k-state-hover {
  background-color: #cde6f7;
}
.k-panelbar > li > .k-state-selected,
.k-panelbar > li > .k-state-selected.k-state-hover,
.k-panelbar > li.k-state-default > .k-link.k-state-selected,
.k-panelbar > li > .k-state-focused.k-state-hover {
  color: #fff;
  background-color: #0072c6;
}
.k-panelbar.k-header,
.k-panelbar .k-content,
.k-panelbar .k-panel,
.k-panelbar .k-item,
.k-panelbar > .k-item > .k-link {
  border-color: #fff;
}
.k-window > .k-header {
  border-color: transparent;
}
.k-window-titlebar .k-state-hover {
  background-color: transparent;
  border-color: transparent;
}
.k-tooltip-validation,
.k-notification {
  -webkit-box-shadow: none;
          box-shadow: none;
}
.k-editor .k-tool {
  border-color: #fff;
}
.k-editor .k-tool.k-state-selected {
  border-color: #cde6f7;
}
.k-editor .k-tool.k-state-hover {
  border-color: #f4f4f4;
}
.k-webkit .k-nav-current > .k-link span + span,
.k-ff .k-nav-current > .k-link span + span,
.k-ie11 .k-nav-current > .k-link span + span,
.k-safari .k-nav-current > .k-link span + span {
  max-width: 400px;
}
@media only screen and (max-width: 1420px) {
  .k-webkit,
  .k-ff,
  .k-ie11,
  .k-safari {
    /* Responsive Scheduler */
  }
  .k-webkit .k-nav-current > .k-link span + span,
  .k-ff .k-nav-current > .k-link span + span,
  .k-ie11 .k-nav-current > .k-link span + span,
  .k-safari .k-nav-current > .k-link span + span {
    max-width: 200px;
  }
}
/* Responsive styles */
@media only screen and (max-width: 1024px) {
  .k-webkit,
  .k-ff,
  .k-ie11,
  .k-safari {
    /* Responsive Scheduler */
    /* Responsive Pager */
  }
  .k-webkit .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view,
  .k-ff .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view,
  .k-ie11 .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view,
  .k-safari .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view,
  .k-webkit .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view:hover,
  .k-ff .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view:hover,
  .k-ie11 .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view:hover,
  .k-safari .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view:hover {
    background-image: none;
    background-position: 50% 50%;
    background-color: transparent;
    border-color: #c9c9c9;
    border-radius: 0;
    text-align: right;
  }
  .k-webkit .k-rtl .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view,
  .k-ff .k-rtl .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view,
  .k-ie11 .k-rtl .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view,
  .k-safari .k-rtl .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view {
    text-align: left;
  }
  .k-webkit .k-scheduler-toolbar > ul.k-scheduler-views.k-state-expanded > li,
  .k-ff .k-scheduler-toolbar > ul.k-scheduler-views.k-state-expanded > li,
  .k-ie11 .k-scheduler-toolbar > ul.k-scheduler-views.k-state-expanded > li,
  .k-safari .k-scheduler-toolbar > ul.k-scheduler-views.k-state-expanded > li {
    border-radius: 0;
  }
  .k-webkit .k-scheduler-toolbar > ul.k-scheduler-views.k-state-expanded > li.k-current-view,
  .k-ff .k-scheduler-toolbar > ul.k-scheduler-views.k-state-expanded > li.k-current-view,
  .k-ie11 .k-scheduler-toolbar > ul.k-scheduler-views.k-state-expanded > li.k-current-view,
  .k-safari .k-scheduler-toolbar > ul.k-scheduler-views.k-state-expanded > li.k-current-view {
    border-radius: 0 0 0 0;
  }
  .k-webkit .k-scheduler-toolbar > ul li:first-child,
  .k-ff .k-scheduler-toolbar > ul li:first-child,
  .k-ie11 .k-scheduler-toolbar > ul li:first-child,
  .k-safari .k-scheduler-toolbar > ul li:first-child,
  .k-webkit .k-scheduler-toolbar > ul li:first-child .k-link,
  .k-ff .k-scheduler-toolbar > ul li:first-child .k-link,
  .k-ie11 .k-scheduler-toolbar > ul li:first-child .k-link,
  .k-safari .k-scheduler-toolbar > ul li:first-child .k-link,
  .k-webkit .k-scheduler-toolbar > ul.k-scheduler-views li,
  .k-ff .k-scheduler-toolbar > ul.k-scheduler-views li,
  .k-ie11 .k-scheduler-toolbar > ul.k-scheduler-views li,
  .k-safari .k-scheduler-toolbar > ul.k-scheduler-views li,
  .k-webkit .k-scheduler-toolbar > ul.k-scheduler-views li .k-link,
  .k-ff .k-scheduler-toolbar > ul.k-scheduler-views li .k-link,
  .k-ie11 .k-scheduler-toolbar > ul.k-scheduler-views li .k-link,
  .k-safari .k-scheduler-toolbar > ul.k-scheduler-views li .k-link {
    border-radius: 0;
    padding-top: 0;
  }
  .k-webkit .k-scheduler-toolbar > ul.k-scheduler-views li:last-child,
  .k-ff .k-scheduler-toolbar > ul.k-scheduler-views li:last-child,
  .k-ie11 .k-scheduler-toolbar > ul.k-scheduler-views li:last-child,
  .k-safari .k-scheduler-toolbar > ul.k-scheduler-views li:last-child,
  .k-webkit .k-scheduler-toolbar > ul.k-scheduler-views li:last-child .k-link,
  .k-ff .k-scheduler-toolbar > ul.k-scheduler-views li:last-child .k-link,
  .k-ie11 .k-scheduler-toolbar > ul.k-scheduler-views li:last-child .k-link,
  .k-safari .k-scheduler-toolbar > ul.k-scheduler-views li:last-child .k-link {
    border-radius: 0 0 0 0;
  }
  .k-webkit .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view:hover,
  .k-ff .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view:hover,
  .k-ie11 .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view:hover,
  .k-safari .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view:hover {
    border-color: #8f8f8f;
    background-image: none;
    background-color: #f4f4f4;
  }
  .k-webkit .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view > .k-link,
  .k-ff .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view > .k-link,
  .k-ie11 .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view > .k-link,
  .k-safari .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view > .k-link,
  .k-webkit .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view:hover > .k-link,
  .k-ff .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view:hover > .k-link,
  .k-ie11 .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view:hover > .k-link,
  .k-safari .k-scheduler-toolbar > ul.k-scheduler-views > li.k-current-view:hover > .k-link {
    color: #333333;
    min-width: 20px;
  }
  .k-webkit .k-scheduler-views > li.k-state-selected > .k-link:after,
  .k-ff .k-scheduler-views > li.k-state-selected > .k-link:after,
  .k-ie11 .k-scheduler-views > li.k-state-selected > .k-link:after,
  .k-safari .k-scheduler-views > li.k-state-selected > .k-link:after {
    display: block;
    content: "";
    position: absolute;
    top: 50%;
    margin-top: -0.5em;
    right: 0.333em;
    width: 16px;
    height: 16px;
  }
  .k-webkit .k-scheduler-toolbar > ul.k-scheduler-views.k-state-expanded,
  .k-ff .k-scheduler-toolbar > ul.k-scheduler-views.k-state-expanded,
  .k-ie11 .k-scheduler-toolbar > ul.k-scheduler-views.k-state-expanded,
  .k-safari .k-scheduler-toolbar > ul.k-scheduler-views.k-state-expanded {
    border-width: 1px 1px 0 1px;
    border-style: solid;
    border-color: #c9c9c9;
    background-color: #fff;
    border-radius: 0;
    border-width: 1px;
    background-image: none;
    -webkit-box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.18);
            box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.18);
  }
  .k-webkit .k-rtl .k-scheduler-toolbar > ul.k-scheduler-views.k-state-expanded,
  .k-ff .k-rtl .k-scheduler-toolbar > ul.k-scheduler-views.k-state-expanded,
  .k-ie11 .k-rtl .k-scheduler-toolbar > ul.k-scheduler-views.k-state-expanded,
  .k-safari .k-rtl .k-scheduler-toolbar > ul.k-scheduler-views.k-state-expanded {
    text-align: left;
  }
  .k-webkit .k-pager-wrap .k-pager-nav,
  .k-ff .k-pager-wrap .k-pager-nav,
  .k-ie11 .k-pager-wrap .k-pager-nav,
  .k-safari .k-pager-wrap .k-pager-nav,
  .k-webkit .k-pager-input,
  .k-ff .k-pager-input,
  .k-ie11 .k-pager-input,
  .k-safari .k-pager-input {
    display: inline-block;
    vertical-align: top;
  }
  .k-webkit .k-pager-numbers,
  .k-ff .k-pager-numbers,
  .k-ie11 .k-pager-numbers,
  .k-safari .k-pager-numbers,
  .k-webkit .k-grid .k-pager-numbers,
  .k-ff .k-grid .k-pager-numbers,
  .k-ie11 .k-grid .k-pager-numbers,
  .k-safari .k-grid .k-pager-numbers {
    position: relative;
    left: 5em;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -webkit-flex-direction: column-reverse;
        -ms-flex-direction: column-reverse;
            flex-direction: column-reverse;
    overflow: visible;
    height: auto;
    -webkit-transform: translatey(-100%);
        -ms-transform: translatey(-100%);
            transform: translatey(-100%);
  }
  .k-webkit .km-pane-wrapper .k-pager-numbers .k-link,
  .k-ff .km-pane-wrapper .k-pager-numbers .k-link,
  .k-ie11 .km-pane-wrapper .k-pager-numbers .k-link,
  .k-safari .km-pane-wrapper .k-pager-numbers .k-link,
  .k-webkit .km-pane-wrapper .k-pager-numbers .k-state-selected,
  .k-ff .km-pane-wrapper .k-pager-numbers .k-state-selected,
  .k-ie11 .km-pane-wrapper .k-pager-numbers .k-state-selected,
  .k-safari .km-pane-wrapper .k-pager-numbers .k-state-selected,
  .k-webkit .km-pane-wrapper .k-pager-wrap > .k-link,
  .k-ff .km-pane-wrapper .k-pager-wrap > .k-link,
  .k-ie11 .km-pane-wrapper .k-pager-wrap > .k-link,
  .k-safari .km-pane-wrapper .k-pager-wrap > .k-link,
  .k-webkit .km-pane-wrapper .k-pager-wrap > .k-pager-info,
  .k-ff .km-pane-wrapper .k-pager-wrap > .k-pager-info,
  .k-ie11 .km-pane-wrapper .k-pager-wrap > .k-pager-info,
  .k-safari .km-pane-wrapper .k-pager-wrap > .k-pager-info {
    padding-top: 0;
    padding-bottom: 0;
  }
  .k-webkit .k-rtl .k-pager-numbers,
  .k-ff .k-rtl .k-pager-numbers,
  .k-ie11 .k-rtl .k-pager-numbers,
  .k-safari .k-rtl .k-pager-numbers,
  .k-webkit .k-rtl .k-grid .k-pager-numbers,
  .k-ff .k-rtl .k-grid .k-pager-numbers,
  .k-ie11 .k-rtl .k-grid .k-pager-numbers,
  .k-safari .k-rtl .k-grid .k-pager-numbers {
    right: 5em;
    width: 5.15em;
  }
  .k-webkit .k-pager-numbers .k-current-page,
  .k-ff .k-pager-numbers .k-current-page,
  .k-ie11 .k-pager-numbers .k-current-page,
  .k-safari .k-pager-numbers .k-current-page,
  .k-webkit .k-grid .k-pager-numbers .k-current-page,
  .k-ff .k-grid .k-pager-numbers .k-current-page,
  .k-ie11 .k-grid .k-pager-numbers .k-current-page,
  .k-safari .k-grid .k-pager-numbers .k-current-page {
    display: block;
    border-left: 0;
    -webkit-transform: translatey(100%);
        -ms-transform: translatey(100%);
            transform: translatey(100%);
  }
  .k-webkit .k-pager-numbers li:not(.k-current-page),
  .k-ff .k-pager-numbers li:not(.k-current-page),
  .k-ie11 .k-pager-numbers li:not(.k-current-page),
  .k-safari .k-pager-numbers li:not(.k-current-page) {
    display: none;
  }
  .k-webkit .k-pager-wrap .k-pager-numbers .k-current-page .k-link,
  .k-ff .k-pager-wrap .k-pager-numbers .k-current-page .k-link,
  .k-ie11 .k-pager-wrap .k-pager-numbers .k-current-page .k-link,
  .k-safari .k-pager-wrap .k-pager-numbers .k-current-page .k-link {
    width: 3.8em;
    line-height: 1.429em;
    padding: 0.3em .429em 0.3em 0.8em;
    border-radius: 0;
    background-image: none;
    background-position: 50% 50%;
    background-color: #fff;
    border: 1px solid #c9c9c9;
  }
  .k-webkit .k-pager-numbers .k-current-page:hover .k-link,
  .k-ff .k-pager-numbers .k-current-page:hover .k-link,
  .k-ie11 .k-pager-numbers .k-current-page:hover .k-link,
  .k-safari .k-pager-numbers .k-current-page:hover .k-link {
    border-radius: 0;
    background-color: #f4f4f4;
    border: 1px solid #8f8f8f;
  }
  .k-webkit .k-pager-numbers .k-current-page .k-link:after,
  .k-ff .k-pager-numbers .k-current-page .k-link:after,
  .k-ie11 .k-pager-numbers .k-current-page .k-link:after,
  .k-safari .k-pager-numbers .k-current-page .k-link:after {
    display: block;
    content: "";
    position: absolute;
    top: 50%;
    margin-top: -0.6em;
    right: 0.6em;
    width: 16px;
    height: 16px;
  }
  .k-webkit .k-pager-numbers + .k-link,
  .k-ff .k-pager-numbers + .k-link,
  .k-ie11 .k-pager-numbers + .k-link,
  .k-safari .k-pager-numbers + .k-link {
    margin-left: 5.7em;
  }
  .k-webkit .k-rtl .k-pager-numbers + .k-link,
  .k-ff .k-rtl .k-pager-numbers + .k-link,
  .k-ie11 .k-rtl .k-pager-numbers + .k-link,
  .k-safari .k-rtl .k-pager-numbers + .k-link {
    margin-right: 6em;
    margin-left: 0;
  }
  .k-webkit .k-pager-wrap .k-pager-numbers .k-state-selected,
  .k-ff .k-pager-wrap .k-pager-numbers .k-state-selected,
  .k-ie11 .k-pager-wrap .k-pager-numbers .k-state-selected,
  .k-safari .k-pager-wrap .k-pager-numbers .k-state-selected,
  .k-webkit .k-pager-wrap .k-pager-numbers .k-link,
  .k-ff .k-pager-wrap .k-pager-numbers .k-link,
  .k-ie11 .k-pager-wrap .k-pager-numbers .k-link,
  .k-safari .k-pager-wrap .k-pager-numbers .k-link {
    display: block;
    margin-top: 0;
    margin-right: 0;
    padding: 1px 5px 1px .8em;
    text-align: left;
    border-width: 0;
  }
  .k-webkit .k-pager-wrap .k-pager-numbers .k-state-selected,
  .k-ff .k-pager-wrap .k-pager-numbers .k-state-selected,
  .k-ie11 .k-pager-wrap .k-pager-numbers .k-state-selected,
  .k-safari .k-pager-wrap .k-pager-numbers .k-state-selected {
    background-color: #cde6f7;
  }
  .k-webkit .k-pager-wrap .k-pager-numbers li:not(.k-current-page) .k-link:hover,
  .k-ff .k-pager-wrap .k-pager-numbers li:not(.k-current-page) .k-link:hover,
  .k-ie11 .k-pager-wrap .k-pager-numbers li:not(.k-current-page) .k-link:hover,
  .k-safari .k-pager-wrap .k-pager-numbers li:not(.k-current-page) .k-link:hover {
    background-color: #f4f4f4;
  }
  .k-webkit .k-pager-numbers.k-state-expanded,
  .k-ff .k-pager-numbers.k-state-expanded,
  .k-ie11 .k-pager-numbers.k-state-expanded,
  .k-safari .k-pager-numbers.k-state-expanded {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    padding: 0;
    border-style: solid;
    border-color: #c9c9c9;
    background-color: #fff;
    border-radius: 0 0 0 0;
    -webkit-box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.18);
            box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.18);
  }
  .k-webkit .k-pager-numbers.k-state-expanded .k-current-page,
  .k-ff .k-pager-numbers.k-state-expanded .k-current-page,
  .k-ie11 .k-pager-numbers.k-state-expanded .k-current-page,
  .k-safari .k-pager-numbers.k-state-expanded .k-current-page {
    margin: -2.2em -1px 0;
    padding: 0;
  }
  .k-webkit .k-pager-numbers.k-state-expanded .k-current-page .k-link,
  .k-ff .k-pager-numbers.k-state-expanded .k-current-page .k-link,
  .k-ie11 .k-pager-numbers.k-state-expanded .k-current-page .k-link,
  .k-safari .k-pager-numbers.k-state-expanded .k-current-page .k-link {
    border-radius: 0 0 0 0;
    background-color: #0072c6;
    border: 1px solid #0072c6;
    border-top-color: #c9c9c9;
    color: #fff;
  }
  .k-webkit .k-pager-numbers.k-state-expanded .k-current-page .k-link:after,
  .k-ff .k-pager-numbers.k-state-expanded .k-current-page .k-link:after,
  .k-ie11 .k-pager-numbers.k-state-expanded .k-current-page .k-link:after,
  .k-safari .k-pager-numbers.k-state-expanded .k-current-page .k-link:after {
    background-position: 0 0;
    opacity: 1;
  }
  .k-webkit .k-pager-numbers.k-state-expanded li,
  .k-ff .k-pager-numbers.k-state-expanded li,
  .k-ie11 .k-pager-numbers.k-state-expanded li,
  .k-safari .k-pager-numbers.k-state-expanded li {
    display: inline-block;
  }
}
@media only screen and (max-width: 640px) {
  .k-webkit .k-pager-info,
  .k-ff .k-pager-info,
  .k-ie11 .k-pager-info,
  .k-safari .k-pager-info {
    display: none;
  }
}
@media only screen and (max-width: 480px) {
  .k-webkit .k-pager-sizes,
  .k-ff .k-pager-sizes,
  .k-ie11 .k-pager-sizes,
  .k-safari .k-pager-sizes {
    display: none;
  }
  .k-webkit .k-scheduler-toolbar .k-i-calendar,
  .k-ff .k-scheduler-toolbar .k-i-calendar,
  .k-ie11 .k-scheduler-toolbar .k-i-calendar,
  .k-safari .k-scheduler-toolbar .k-i-calendar {
    display: inline-block;
  }
}
/* Default Theme */
.k-chart .k-mask {
  background-color: #fff;
  filter: alpha(opacity=68);
  opacity: 0.68;
}
.k-chart .k-selection {
  border-color: #e5e5e5;
}
.k-chart .k-handle {
  width: 15px;
  height: 15px;
  background-color: #00b0ff;
  border-radius: 10px;
}
.k-chart .k-leftHandle {
  left: -8px;
}
.k-chart .k-rightHandle {
  right: -8px;
}
.k-chart .k-handle:hover {
  background-color: #00b0ff;
  border-color: #00b0ff;
}
.k-chart .k-navigator-hint .k-tooltip {
  border: 3px solid #ffffff;
  -webkit-box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.2);
          box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.2);
  background: #ffffff;
  color: #242424;
}
.k-chart .k-navigator-hint .k-scroll {
  background: #0072c6;
  height: 4px;
}
.k-chart-tooltip {
  background-image: none;
}
/* Map */
.k-map .k-marker {
  background-image: url("Office365/markers.png");
}
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2) {
  .k-map .k-marker {
    background-image: url("Office365/markers_2x.png");
  }
}
.k-map .k-attribution {
  color: #666666;
}
.k-spreadsheet-row-header,
.k-spreadsheet-column-header {
  background-color: #fff;
}
.k-spreadsheet-top-corner,
.k-spreadsheet-row-header,
.k-spreadsheet-column-header {
  background-color: #fff;
  background-image: none;
  color: #000000;
  border-color: #dadada;
}
.k-spreadsheet-top-corner {
  border-color: #dadada;
}
.k-spreadsheet-top-corner:after {
  border-color: transparent #dadada #dadada transparent;
}
.k-spreadsheet-pane {
  border-color: #c8c8c8;
}
.k-spreadsheet-pane .k-spreadsheet-vaxis,
.k-spreadsheet-pane .k-spreadsheet-haxis {
  border-color: #c8c8c8;
}
.k-spreadsheet-pane .k-spreadsheet-column-header,
.k-spreadsheet-pane .k-spreadsheet-row-header {
  border-color: #dadada;
}
.k-spreadsheet-pane .k-spreadsheet-merged-cell {
  background-color: #fff;
}
.k-spreadsheet-pane .k-selection-partial,
.k-spreadsheet-pane .k-selection-full {
  border-color: rgba(0, 114, 198, 0.2);
  background-color: rgba(0, 114, 198, 0.2);
}
.k-spreadsheet-pane .k-filter-range {
  border-color: #0072c6;
}
.k-spreadsheet-pane .k-spreadsheet-column-header .k-selection-partial,
.k-spreadsheet-pane .k-spreadsheet-column-header .k-selection-full {
  border-bottom-color: #0072c6;
}
.k-spreadsheet-pane .k-spreadsheet-row-header .k-selection-partial,
.k-spreadsheet-pane .k-spreadsheet-row-header .k-selection-full {
  border-right-color: #0072c6;
}
.k-auto-fill,
.k-spreadsheet-selection {
  border-color: #0072c6;
  -webkit-box-shadow: inset 0 0 0 1px #fff, 0 0 0 1px #0072c6;
          box-shadow: inset 0 0 0 1px #fff, 0 0 0 1px #0072c6;
}
.k-spreadsheet-selection {
  background-color: rgba(0, 114, 198, 0.2);
}
.k-spreadsheet-active-cell {
  border-color: #0072c6 !important;
  background-color: #fff;
}
.k-spreadsheet-active-cell.k-single {
  background-color: #fff;
}
.k-spreadsheet > .k-spreadsheet-formula-bar {
  background-color: #fff;
  border-color: #fff #fff #dadada;
}
.k-spreadsheet > .k-spreadsheet-formula-bar:before {
  border-color: #dadada;
}
.k-spreadsheet > .k-spreadsheet-formula-bar:after {
  border-color: #fff;
}
.k-spreadsheet .k-spreadsheet-formula-input {
  background-color: #fff;
  color: #333333;
}
.k-spreadsheet .k-resize-handle,
.k-spreadsheet .k-resize-hint-handle,
.k-spreadsheet .k-resize-hint-marker {
  background-color: #cde6f7;
}
.k-spreadsheet .k-resize-hint-vertical .k-resize-hint-handle,
.k-spreadsheet .k-resize-hint-vertical .k-resize-hint-marker {
  background-color: #cde6f7;
}
.k-spreadsheet .k-single-selection::after {
  background-color: #0072c6;
  border-color: #fff;
}
.k-spreadsheet .k-auto-fill-punch {
  background-color: rgba(255, 255, 255, 0.5);
}
.k-spreadsheet .k-single-selection.k-dim-auto-fill-handle::after {
  background-color: rgba(0, 114, 198, 0.5);
}
.k-spreadsheet-format-cells .k-spreadsheet-preview {
  border-color: #c9c9c9;
}
.k-spreadsheet-filter {
  border-radius: 0;
  background-color: #fff;
  -webkit-box-shadow: inset 0 0 0 1px #c8c8c8;
          box-shadow: inset 0 0 0 1px #c8c8c8;
}
.k-spreadsheet-filter.k-state-active {
  color: #333333;
  background-color: #cde6f7;
}
.k-spreadsheet-filter:hover {
  color: #000;
  background: #f4f4f4;
  border-color: #e0e0e0;
}
.k-action-window .k-action-buttons {
  border-color: #c9c9c9;
}
.k-spreadsheet-sample {
  color: #808080;
}
.k-state-selected .k-spreadsheet-sample {
  color: inherit;
}
.k-spreadsheet-window .k-list-wrapper,
.k-spreadsheet-window .k-list {
  border-color: #c9c9c9;
  border-radius: 0;
}
.k-spreadsheet-window .export-config,
.k-spreadsheet-window .k-edit-field > .k-orientation-label {
  border-color: #c9c9c9;
}
.k-spreadsheet-window .k-edit-field > input[type="radio"]:checked + .k-orientation-label {
  background-image: none;
  background-color: #cde6f7;
  color: #525252;
}
.k-spreadsheet-window .k-page-orientation {
  border-color: #0075cb;
  -webkit-box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1);
          box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1);
}
.k-spreadsheet-window .k-page-orientation:before {
  background: #fff;
  border-color: transparent;
  border-bottom-color: #0075cb;
  border-left-color: #0075cb;
}
.k-spreadsheet-window .k-margins-horizontal,
.k-spreadsheet-window .k-margins-vertical {
  background: transparent;
  border-color: #c9c9c9;
}
.k-spreadsheet-toolbar.k-toolbar .k-button-group .k-button {
  border-radius: 0;
}
.k-spreadsheet-toolbar > .k-widget,
.k-spreadsheet-toolbar > .k-button,
.k-spreadsheet-toolbar > .k-button-group {
  border-radius: 0;
}
.k-spreadsheet-toolbar > .k-separator {
  border-color: #c9c9c9;
}
.k-spreadsheet-toolbar .k-overflow-anchor {
  border-radius: 0;
}
.k-spreadsheet-popup {
  border-radius: 0;
}
.k-spreadsheet-popup .k-separator {
  background-color: #c9c9c9;
}
.k-spreadsheet-popup .k-button {
  background-color: transparent;
}
.k-spreadsheet-popup .k-button:hover {
  background-color: #f4f4f4;
}
.k-spreadsheet-popup .k-state-active {
  background-color: #cde6f7;
  color: #ffffff;
}
.k-spreadsheet-popup .k-state-active:hover {
  background-color: #a1d0f0;
}
.k-spreadsheet-filter-menu .k-details {
  border-color: #c9c9c9;
}
.k-spreadsheet-filter-menu .k-details-content .k-space-right {
  background-color: #fff;
}
.k-spreadsheet-filter-menu .k-spreadsheet-value-treeview-wrapper {
  background-color: #fff;
  border-color: #c9c9c9;
  border-radius: 0 0 0 0;
}
.k-syntax-ref {
  color: #ff8822;
}
.k-syntax-num {
  color: #0099ff;
}
.k-syntax-func {
  font-weight: bold;
}
.k-syntax-str {
  color: #38b714;
}
.k-syntax-error {
  color: red;
}
.k-syntax-bool {
  color: #a9169c;
}
.k-syntax-startexp {
  font-weight: bold;
}
.k-syntax-paren-match {
  background-color: #caf200;
}
.k-series-a {
  border-color: #0072c6;
  background-color: rgba(0, 114, 198, 0.15);
}
.k-series-b {
  border-color: #5db2ff;
  background-color: rgba(93, 178, 255, 0.15);
}
.k-series-c {
  border-color: #008a17;
  background-color: rgba(0, 138, 23, 0.15);
}
.k-series-d {
  border-color: #82ba00;
  background-color: rgba(130, 186, 0, 0.15);
}
.k-series-e {
  border-color: #ff8f32;
  background-color: rgba(255, 143, 50, 0.15);
}
.k-series-f {
  border-color: #ac193d;
  background-color: rgba(172, 25, 61, 0.15);
}
.k-spreadsheet-sheets-remove:hover .k-icon {
  color: #cc2222;
}
.k-spreadsheet-formula-list .k-state-focused {
  background-color: #cde6f7;
  color: #333333;
}
@media only screen and (-webkit-min-device-pixel-ratio: 2) {
  .k-icon.k-font-icon {
    background-image: none;
  }
}
.k-spreadsheet-sheets-bar {
  background-color: #f4f4f4;
}
.k-spreadsheet-sheets-bar .k-item,
.k-spreadsheet-sheets-bar .k-item.k-state-active {
  border-color: #dadada;
}
